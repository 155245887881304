<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card ">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4> Identity card management</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link>
                    \  Identity card management
                  </div>
                </div>
                <div class="breadcrumb-right" v-if="import_excel">


                        <v-file-input outlined dense v-model="excel_file" prepend-icon=""
                                      prepend-inner-icon="mdi-file"
                                  accept=".xlsx, .xls, .csv"
                                  label="File input"
                    ></v-file-input>


                  <v-btn v-if="checkIsAccessible('id-card', 'create')" @click="importStudent()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined> fa-file-import</v-icon>&nbsp;
                    Import
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                        class="form-control"
                        v-model="search.name"
                        label="Name, Email, Phone, Symbol no"
                        outlined  v-on:keyup.enter="getUsers()"
                        dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        :items="levels"
                        class="form-control"
                        v-model="search.level_id"
                        label="Level"
                        item-value="id"
                        item-text="title"
                        outlined  v-on:keyup.enter="getUsers()"
                        @change="getPrograms"
                        dense>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="search.program_id"
                        :items="programs"
                        label="Programs"
                        item-value="id"
                        item-text="title"  v-on:keyup.enter="getUsers()"
                        @change="getGrades">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        :items="academicYears"
                        class="form-control"
                        v-model="search.academic_year_id"
                        label="Academic year"
                        item-value="id"
                        item-text="year"
                        outlined  v-on:keyup.enter="getUsers()"
                        dense>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="search.grade_id"
                        :items="gradesLevels"
                        label="Semester/Year"
                        item-value="id"
                        item-text="title"  v-on:keyup.enter="getUsers()"
                        @change="getAcademicClasses"
                        clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="search.class_id"
                        :items="academic_classes"
                        label="Class name"
                        item-value="id"
                        item-text="title"
                        clearable  v-on:keyup.enter="getUsers()"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="search.type"
                        :items="user_type"
                        label="User Type"
                        item-value="value"
                        item-text="name"
                        v-on:keyup.enter="getUsers()"
                        clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        outlined
                        dense
                        class="form-control"
                        v-model="search.id_type"
                        :items="printOption"
                        label="Type"
                        item-value="value"
                        item-text="name"
                        v-on:keyup.enter="getUsers()"
                        clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="12" class="text-right">
                    <v-btn
                        @click.prevent="getUsers()"
                        class="btn btn-primary  w-35 float-right"
                        :loading="loading"
                        dark
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <!--old design-->
<!--              <div class="wizard wizard-2" id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true">
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">

              <div class="row" v-if="import_excel">
                <div class="col-10">
                  <v-file-input outlined dense v-model="excel_file"
                                accept=".xlsx, .xls, .csv"
                                label="File input"
                  ></v-file-input>
                </div>
                <div class="col-2" v-if="checkIsAccessible('id-card', 'create')">
                  <v-btn @click="importStudent" :loading="isLoading" :disabled="!excel_file"
                         class="btn btn-primary ml-2 text-white">Import
                  </v-btn>
                </div>
              </div>
              <v-row class="text-center">
&lt;!&ndash;                <v-col cols="12" class="text-center">
                  <div class="card-body">
                    <v-form id="kt_password_change_form" class="row bg-filter">
                      <div class="form-group row">
                        <div class="col-lg-3">
                          <v-text-field
                              class="form-control"
                              v-model="search.name"
                              label="Name, Email, Phone, Symbol no"
                              outlined
                              dense>
                          </v-text-field>
                        </div>
                        <div class="col-lg-3">
                          <v-select
                              :items="levels"
                              class="form-control"
                              v-model="search.level_id"
                              label="Level"
                              item-value="id"
                              item-text="title"
                              outlined
                              @change="getPrograms"
                              dense>
                          </v-select>
                        </div>


                        <div class="col-lg-3">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.program_id"
                              :items="programs"
                              label="Programs"
                              item-value="id"
                              item-text="title"
                              @change="getGrades"
                          >
                          </v-select>

                        </div>

                        <div class="col-lg-3">
                          <v-select
                              :items="academicYears"
                              class="form-control"
                              v-model="search.academic_year_id"
                              label="Academic year"
                              item-value="id"
                              item-text="year"
                              outlined
                              dense>
                          </v-select>
                        </div>
                        <div class="col-lg-3">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.grade_id"
                              :items="gradesLevels"
                              label="Semester/Year"
                              item-value="id"
                              item-text="title"
                              @change="getAcademicClasses"
                              clearable
                          ></v-select>

                        </div>
                        <div class="col-lg-3">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.class_id"
                              :items="academic_classes"
                              label="Class name"
                              item-value="id"
                              item-text="title"
                              clearable
                          ></v-select>

                        </div>
                        <div class="col-lg-3">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.type"
                              :items="user_type"
                              label="User Type"
                              item-value="value"
                              item-text="name"
                              @change="getUsers"
                              clearable
                          ></v-select>

                        </div>
                        <div class="col-lg-3">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.id_type"
                              :items="printOption"
                              label="Type"
                              item-value="value"
                              item-text="name"
                              @change="getUsers"
                              clearable
                          ></v-select>

                        </div>
                        <div class="col-lg-4">
                          <v-btn :loading="loading"
                                 @click.prevent="getUsers"
                                 type="submit"
                                 class=" btn btn-primary text-white mr-2"
                                 name="search"
                                 ref="search"
                          ><i class="fas fa-search"></i> Search
                          </v-btn>
                          <v-btn
                              class="btn btn-secondary"
                              @click.prevent="resetFilter"

                          ><i class="fas fa-redo-alt"></i>Reset
                          </v-btn>
                        </div>


                      </div>
                    </v-form>
                  </div>

                </v-col>&ndash;&gt;

                <div class="col-12 text-left" v-if="studentList.length>0">
                  <h4>Selected Users </h4>
                  <table class="table table-stripe">
                    <thead>
                    <th>Image</th>
                    <th>Name</th>
                    <th v-if="search.id_type=='printed'">Print status</th>
                    <th>Symbol number</th>
                    <th>Program</th>
                    <th>Semester/Year</th>
                    <th>Class</th>
                    <th>Status</th>
                    <th>Action</th>
                    </thead>
                    <tbody>
                    <tr v-for="(user, index) of studentList" :key="index">
                      <td>
                        <div class="symbol-label">
                          <img v-if="user.image_path && user.image_path.lenght>0"
                               :src="user.image_path.thumb"
                               class="cursor-pointer" alt=""
                               @click="changeImage(user)"
                               style="height: 30px;">
                          <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                        @click="changeImage(user)"> {{
                                                        user.first_name.charAt(0)
                                                      }} </span>
                                                </span>
                        </div>
                      </td>
                      <td>
                        {{ (user.full_name) ? user.full_name : null }}
                      </td>
                      <td v-if="search.id_type==='printed'">
                        <span class="badge badge-success">PRINTED</span>
                      </td>
                      <td>{{ user.setting ? user.setting.symbol_no : "N/A" }}
                      </td>
                      <td>{{
                          user.setting ? user.setting.program_title : "N/A"
                        }}
                      </td>
                      <td>{{
                          user.setting ? user.setting.current_semester_title
                              : "N/A"
                        }}
                      </td>
                      <td>{{
                          user.setting ? user.setting.current_class_title :
                              "N/A"
                        }}
                      </td>
                      <td>
                      <span
                          :class="user.is_active?'badge badge-success':'badge badge-danger'">{{
                          user.is_active ? 'Active' : 'Inactive'
                        }}</span>
                      </td>
                      <td>

                        <v-btn class="navi-link" text @click="removeStudentFromList(index)"
                        >
                          &lt;!&ndash;                                                            <span class="navi-icon">&ndash;&gt;
                          &lt;!&ndash;                                                              <i class="flaticon-edit"></i>&ndash;&gt;
                          &lt;!&ndash;                                                            </span>&ndash;&gt;
                          <span class="navi-text red&#45;&#45;text">Remove</span>
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>


                <v-col cols="12" v-if="selected.length>0">
                  <v-card flat color="grey lighten-4">
                    <v-toolbar flat color="grey lighten-4">
                      <v-toolbar-title>Total Selected : {{ selected.length }}</v-toolbar-title>
                      <v-toolbar-title class="ml-5"> Actions :</v-toolbar-title>
                      <v-btn depressed @click="addStudents">
                        <v-icon>assignment_ind</v-icon>
                        Add to Selection List

                      </v-btn>
                      <v-btn depressed @click="dialog = true;">
                        <v-icon>assignment_ind</v-icon>
                        Prepare ID Card's
                      </v-btn>
                      <v-btn depressed @click="previewIdCard">
                        <v-icon>assignment_ind</v-icon>
                        Preview Selected
                      </v-btn>

                    </v-toolbar>
                  </v-card>

                </v-col>
              </v-row>
              <div class="table-responsive" v-if="studentList.length > 0">
                <table class="table">
                  <thead>
                    <tr class="px-3">
                    <th>Image</th>
                    <th>Name</th>
                    <th v-if="search.id_type=='printed'">Print status</th>
                    <th>Symbol number</th>
                    <th>Program</th>
                    <th>Semester/Year</th>
                    <th>Class</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(user, index) of studentList" :key="index">
                    <td>
                      <div class="symbol-label">
                        <img v-if="user.image_path && user.image_path.lenght>0"
                             :src="user.image_path.thumb"
                             class="cursor-pointer" alt=""
                             @click="changeImage(user)"
                             style="height: 30px;">
                        <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                        @click="changeImage(user)"> {{
                                                        user.first_name.charAt(0)
                                                      }} </span>
                                                </span>
                      </div>
                    </td>
                    <td>
                      {{ (user.full_name) ? user.full_name : null }}
                    </td>
                    <td v-if="search.id_type==='printed'">
                      <span class="badge badge-success">PRINTED</span>
                    </td>
                    <td>{{ user.setting ? user.setting.symbol_no : "N/A" }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.program_title : "N/A"
                      }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.current_semester_title
                            : "N/A"
                      }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.current_class_title :
                            "N/A"
                      }}
                    </td>
                    <td>
                      <span
                          :class="user.is_active?'badge badge-success':'badge badge-danger'">{{
                          user.is_active ? 'Active' : 'Inactive'
                        }}</span>
                    </td>
                    <td>

                      <v-btn class="navi-link" text @click="removeStudentFromList(index)"
                      >
                        &lt;!&ndash;                                                            <span class="navi-icon">&ndash;&gt;
                        &lt;!&ndash;                                                              <i class="flaticon-edit"></i>&ndash;&gt;
                        &lt;!&ndash;                                                            </span>&ndash;&gt;
                        <span class="navi-text red&#45;&#45;text">Remove</span>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              &lt;!&ndash;old design&ndash;&gt;
              <div class="col-md-12" v-if="loading">
                <v-skeleton-loader
                    v-bind="attrs"
                    type="table"
                ></v-skeleton-loader>
              </div>
              <div class="col-12" v-if="users.length>0 && !loading">
                <table class="table table-stripe">
                  <thead>
                  <th>
                    <v-checkbox class="id-card__checkbox" @change="selectAll()" v-model="checkAll"></v-checkbox>
                  </th>
                  <th>Image</th>
                  <th>
                    Full Name
                  </th>

                  <th v-if="search.id_type=='printed' || search.id_type=='ready'">Print Status</th>
                  <th>CRN</th>
                  <th>Program</th>
                  <th>Class</th>
                  &lt;!&ndash;                                    <th>Action</th>&ndash;&gt;
                  </thead>
                  <tbody>
                  <tr v-for="(user, index) of users" :key="index">
                    <td>
                      <v-checkbox v-model="selected" :value="user"></v-checkbox>
                    </td>
                    <td>
                      <div class="symbol-label">
                        <img v-if="user.image_path && user.image_path.real"
                             :src="user.image_path.real"
                             class="cursor-pointer" alt=""
                             @click="changeImage(user)"
                             style="height: 30px;">
                        <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                        @click="changeImage(user)"> {{
                                                        (user.full_name) ? user.full_name.charAt(0) : null
                                                      }} </span>
                                                </span>
                      </div>
                    </td>
                    <td>
                      <router-link v-if="user.type"
                                   :to="{name:'students-summary', params:{id:user.id, type:user.type[0]}}">
                        <a href="#" class="navi-link"
                        >
                          {{ user.full_name ? user.full_name : '' }}
                        </a>
                        <i :class="user.is_active?'fas fa-circle dot-active':'fas fa-circle dot-inactive'"></i>
                      </router-link>
                    </td>

                    <td v-if="search.id_type=='printed' || search.id_type=='ready'">
                                            <span class="badge badge-success"
                                                  v-if="search.id_type==='printed'">PRINTED</span>
                      <span class="badge badge-success"
                            v-if="search.id_type==='ready'">FILE READY</span>
                    </td>
                    <td>
                      {{ user && user.setting && user.setting.midas_id ? user.setting.midas_id : "" }}
                      <br>
                      {{ user.personal_id ? user.personal_id : "N/A" }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.program_title : "N/A"
                      }}<br/>
                      {{
                        user.setting ? user.setting.current_semester_title
                            : "N/A"
                      }}
                    </td>
                    <td>{{ user.setting ? user.setting.current_class_title : "N/A" }}
                    </td>
                    &lt;!&ndash;                                        <td>&ndash;&gt;
                    &lt;!&ndash;                                            <div class="kt-widget__toolbar">&ndash;&gt;

                    &lt;!&ndash;                                                <b-dropdown&ndash;&gt;
                    &lt;!&ndash;                                                        size="sm"&ndash;&gt;
                    &lt;!&ndash;                                                        variant="link"&ndash;&gt;
                    &lt;!&ndash;                                                        toggle-class="custom-v-dropdown"&ndash;&gt;
                    &lt;!&ndash;                                                        no-caret&ndash;&gt;
                    &lt;!&ndash;                                                        right&ndash;&gt;
                    &lt;!&ndash;                                                        no-flip&ndash;&gt;
                    &lt;!&ndash;                                                        text="Actions"&ndash;&gt;

                    &lt;!&ndash;                                                >&ndash;&gt;
                    &lt;!&ndash;                                                    <template v-slot:button-content>&ndash;&gt;
                    &lt;!&ndash;                                                        <a href="#" class="" data-toggle="dropdown">&ndash;&gt;
                    &lt;!&ndash;                                                            &lt;!&ndash;              <span class="svg-icon svg-icon-success svg-icon-2x">&ndash;&gt;&ndash;&gt;
                    &lt;!&ndash;                                                            &lt;!&ndash;begin::Svg Icon&ndash;&gt;&ndash;&gt;
                    &lt;!&ndash;                                                            <i class="flaticon-more-1"></i>&ndash;&gt;
                    &lt;!&ndash;                                                            &lt;!&ndash;end::Svg Icon&ndash;&gt;&ndash;&gt;
                    &lt;!&ndash;                                                            &lt;!&ndash;              </span>&ndash;&gt;&ndash;&gt;
                    &lt;!&ndash;                                                        </a>&ndash;&gt;
                    &lt;!&ndash;                                                    </template>&ndash;&gt;
                    &lt;!&ndash;                                                    &lt;!&ndash;begin::Navigation&ndash;&gt;&ndash;&gt;
                    &lt;!&ndash;                                                    <div class="navi navi-hover">&ndash;&gt;


                    &lt;!&ndash;                                                        <b-dropdown-text tag="div" class="navi-item">&ndash;&gt;

                    &lt;!&ndash;                                                            <router-link v-if="user.type"&ndash;&gt;
                    &lt;!&ndash;                                                                         :to="{name:'students-summary', params:{id:user.id, type:user.type[0]}}">&ndash;&gt;
                    &lt;!&ndash;                                                                <a href="#" class="navi-link"&ndash;&gt;
                    &lt;!&ndash;                                                                >&ndash;&gt;
                    &lt;!&ndash;                                                            <span class="navi-icon">&ndash;&gt;
                    &lt;!&ndash;                                                              <i class="flaticon-edit"></i>&ndash;&gt;
                    &lt;!&ndash;                                                            </span>&ndash;&gt;
                    &lt;!&ndash;                                                                    <span class="navi-text">Edit</span>&ndash;&gt;
                    &lt;!&ndash;                                                                </a>&ndash;&gt;
                    &lt;!&ndash;                                                            </router-link>&ndash;&gt;
                    &lt;!&ndash;                                                        </b-dropdown-text>&ndash;&gt;

                    &lt;!&ndash;                                                    </div>&ndash;&gt;
                    &lt;!&ndash;                                                    &lt;!&ndash;end::Navigation&ndash;&gt;&ndash;&gt;
                    &lt;!&ndash;                                                </b-dropdown>&ndash;&gt;
                    &lt;!&ndash;                                            </div>&ndash;&gt;

                    &lt;!&ndash;                                        </td>&ndash;&gt;
                  </tr>
                  </tbody>
                </table>
                &lt;!&ndash;                                <div class="col-12 text-right" v-if="users.length">&ndash;&gt;
                &lt;!&ndash;                                    <b-pagination&ndash;&gt;
                &lt;!&ndash;                                            @input="getUsers"&ndash;&gt;
                &lt;!&ndash;                                            v-model="page"&ndash;&gt;
                &lt;!&ndash;                                            :total-rows="total"&ndash;&gt;
                &lt;!&ndash;                                            :per-page="perPage"&ndash;&gt;
                &lt;!&ndash;                                    ></b-pagination>&ndash;&gt;
                &lt;!&ndash;                                </div>&ndash;&gt;
                <v-dialog v-model="uploadDialog" max-width="600">
                  <v-card>
                    <v-card-title>
                      Change Image
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="4" v-if="user">
                          <div class="symbol-label">
                            <img v-if="user.photo" :src="user.image_path.thumb" class=""
                                 alt=""
                                 style="height: 150px;">
                            <span v-else class="symbol  symbol-light-success ">
                                                    <span class="symbol-label font-size-h5 font-weight-bold p-5"
                                                    >Upload Image</span>
                                                </span>
                          </div>
                        </v-col>
                        <v-col cols="8'">
                          <v-file-input
                              :rules="rules"
                              v-model="user.photo"
                              outlined
                              dense
                              accept="image/png, image/jpeg, image/bmp"
                              prepend-icon="mdi-camera"
                              label="Profile Image"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                          <div class="float-right">
                            <v-btn
                                @click="uploadDialog = false"
                                class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                                depressed
                            >Cancel
                            </v-btn>
                            <v-btn
                                class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                                depressed
                                @click="updateStudent"
                                :loading="isBusy"
                            >Upload & Save
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>

                    </v-card-text>

                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="600">
                  <v-card>
                    <v-card-title>
                      ID Card Print Settings
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              v-model="new_id_card.file_name"
                              label="File name"
                              outlined
                              dense
                          ></v-text-field>
                          <span class="text-danger"
                                v-if="$v.new_id_card.file_name.$error">File Name  is  required.</span>
                        </v-col>
                        <v-col cols="6">
                          <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  dense
                                  outlined
                                  v-model="expiryDate"
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Card expiry (Date)"
                                  class="form-control"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="expiryDate"
                                @input="menu = false; convertAdToBs()"
                                header-menu="primary"
                            ></v-date-picker>
                          </v-menu>

                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                              v-model="dob_bs"
                              label="Card expiry (BS)"
                              prepend-icon="mdi-calendar"
                              outlined
                              dense
                              readonly
                          ></v-text-field>

                        </v-col>
                        <v-col cols="12">
                          <v-select
                              v-model="new_id_card.print_id"
                              :items="printers"
                              item-value="id"
                              item-text="title"
                              label="Select printer"
                              outlined
                              dense
                              clearable
                          ></v-select>
                          <span class="text-danger" v-if="$v.new_id_card.print_id.$error">Printer is  required.</span>
                        </v-col>
                        <v-col cols="12" class="text-right">
                          <v-btn :loading="loading" depressed outlined @click="saveIds()"
                                 class="pull-right btn btn-primary text-white">
                            Save setting & send to prepare PDF
                          </v-btn>
                        </v-col>
                      </v-row>


                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>

            </div>
          </div>
        </div>-->
              <v-col cols="12" v-if="selected.length>0">
                <v-card flat color="grey lighten-4">
                  <v-toolbar flat color="grey lighten-4">
                    <v-toolbar-title>Total selected : {{ selected.length }}</v-toolbar-title>
                    <v-toolbar-title class="ml-5"> Actions: </v-toolbar-title>
                    <v-btn depressed @click="addStudents" class="ml-2">
                      <v-icon>assignment_ind</v-icon>
                      Add to selection list

                    </v-btn>
                    <v-btn depressed @click="dialog = true;" class="ml-2">
                      <v-icon>assignment_ind</v-icon>
                      Prepare ID cards
                    </v-btn>
                    <v-btn depressed @click="previewIdCard" class="ml-2">
                      <v-icon>assignment_ind</v-icon>
                      Preview selected
                    </v-btn>

                  </v-toolbar>
                </v-card>

              </v-col>
              <!--new design-->

              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading">
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25">
                </v-skeleton-loader>
                <table class="table" v-if="studentList.length > 0">
                  <thead>
                  <tr class="px-3">
                    <th>Image</th>
                    <th>Name</th>
                    <th v-if="search.id_type=='printed'">Print status</th>
                    <th>Symbol number</th>
                    <th>Program</th>
                    <th>Semester/Year</th>
                    <th>Class</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(user, index) of studentList" :key="index">
                    <td>
                      <div class="symbol-label">
                        <img v-if="user.image_path && user.image_path.lenght>0"
                             :src="user.image_path.thumb"
                             class="cursor-pointer" alt=""
                             @click="changeImage(user)"
                             style="height: 30px;">
                        <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                        @click="changeImage(user)"> {{
                                                        user.first_name.charAt(0)
                                                      }} </span>
                                                </span>
                      </div>
                    </td>
                    <td>
                      {{ (user.full_name) ? user.full_name : null }}
                    </td>
                    <td v-if="search.id_type==='printed'">
                      <span class="badge badge-success">PRINTED</span>
                    </td>
                    <td>{{ user.setting ? user.setting.symbol_no : "N/A" }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.program_title : "N/A"
                      }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.current_semester_title
                            : "N/A"
                      }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.current_class_title :
                            "N/A"
                      }}
                    </td>
                    <td>
                      <span
                          :class="user.is_active?'badge badge-success':'badge badge-danger'">{{
                          user.is_active ? 'Active' : 'Inactive'
                        }}</span>
                    </td>
                    <td>

                      <v-btn class="navi-link" text @click="removeStudentFromList(index)"
                      >
                        <!--                                                            <span class="navi-icon">-->
                        <!--                                                              <i class="flaticon-edit"></i>-->
                        <!--                                                            </span>-->
                        <span class="navi-text red--text">Remove</span>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="table"  v-if="users.length>0 && !loading">
                  <thead>
                  <tr class="px-3">
                    <th class="px-3">
                      <v-checkbox class="id-card__checkbox" @change="selectAll()" v-model="checkAll"></v-checkbox>
                    </th>
                    <th>Image</th>
                    <th>Full name</th>
                    <th v-if="search.id_type=='printed' || search.id_type=='ready'">Print Status</th>
                    <th>External ID / CRN</th>
                    <th>Program</th>
                    <th>Class</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(user, index) of users" :key="index">
                    <td class="px-3">
                      <v-checkbox v-model="selected" :value="user"></v-checkbox>
                    </td>
                    <td>
                      <div class="symbol-label">
                        <img v-if="user.image_path && user.image_path.real" :src="user.image_path.real"
                             class="cursor-pointer" alt=""
                             @click="changeImage(user)"
                             style="height: 30px;">
                          <span v-else class="symbol symbol-35 symbol-light-success">
                            <span class="symbol-label font-size-h5 font-weight-bold cursor-pointer" @click="changeImage(user)">
                              {{(user.full_name) ? user.full_name.charAt(0) : null }}
                            </span>
                          </span>
                      </div>
                    </td>
                    <td>
                      <router-link v-if="user.type"
                                   :to="{name:'students-summary', params:{id:user.id, type:user.type[0]}}">
                        <a href="#" class="navi-link"
                        >
                          {{ user.full_name ? user.full_name : '' }}
                        </a>
                        &nbsp;&nbsp;<i :class="user.is_active?'fas fa-circle dot-active':'fas fa-circle dot-inactive'"></i>
                      </router-link>
                    </td>

                    <td v-if="search.id_type=='printed' || search.id_type=='ready'">
                                            <span class="badge badge-success"
                                                  v-if="search.id_type==='printed'">PRINTED</span>
                      <span class="badge badge-success"
                            v-if="search.id_type==='ready'">FILE READY</span>
                    </td>
                    <td>
                      <div class="text-secondary">
                        <span class="font-weight-medium">
                          {{ user && user.setting && user.setting.midas_id ? user.setting.midas_id : "" }}
                        </span>
                      </div>
                      <div class="text-secondary">
                        <span class="font-weight-medium">
                          {{ user.personal_id ? user.personal_id : "N/A" }}
                         </span>
                      </div>
                    </td>
                    <td>
                      <div class="text-secondary">
                           <span class="font-weight-medium">
                            {{user.setting ? user.setting.program_title : "N/A" }}
                           </span>
                      </div>
                      <div class="text-secondary">
                         <span class="font-weight-medium">
                            {{ user.setting ? user.setting.current_semester_title : "N/A" }}
                         </span>
                      </div>
                    </td>
                    <td>
                      <span class="font-weight-medium">{{ user.setting ? user.setting.current_class_title : "N/A" }}</span>
                    </td>
                    <!--                                        <td>-->
                    <!--                                            <div class="kt-widget__toolbar">-->

                    <!--                                                <b-dropdown-->
                    <!--                                                        size="sm"-->
                    <!--                                                        variant="link"-->
                    <!--                                                        toggle-class="custom-v-dropdown"-->
                    <!--                                                        no-caret-->
                    <!--                                                        right-->
                    <!--                                                        no-flip-->
                    <!--                                                        text="Actions"-->

                    <!--                                                >-->
                    <!--                                                    <template v-slot:button-content>-->
                    <!--                                                        <a href="#" class="" data-toggle="dropdown">-->
                    <!--                                                            &lt;!&ndash;              <span class="svg-icon svg-icon-success svg-icon-2x">&ndash;&gt;-->
                    <!--                                                            &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
                    <!--                                                            <i class="flaticon-more-1"></i>-->
                    <!--                                                            &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                    <!--                                                            &lt;!&ndash;              </span>&ndash;&gt;-->
                    <!--                                                        </a>-->
                    <!--                                                    </template>-->
                    <!--                                                    &lt;!&ndash;begin::Navigation&ndash;&gt;-->
                    <!--                                                    <div class="navi navi-hover">-->


                    <!--                                                        <b-dropdown-text tag="div" class="navi-item">-->

                    <!--                                                            <router-link v-if="user.type"-->
                    <!--                                                                         :to="{name:'students-summary', params:{id:user.id, type:user.type[0]}}">-->
                    <!--                                                                <a href="#" class="navi-link"-->
                    <!--                                                                >-->
                    <!--                                                            <span class="navi-icon">-->
                    <!--                                                              <i class="flaticon-edit"></i>-->
                    <!--                                                            </span>-->
                    <!--                                                                    <span class="navi-text">Edit</span>-->
                    <!--                                                                </a>-->
                    <!--                                                            </router-link>-->
                    <!--                                                        </b-dropdown-text>-->

                    <!--                                                    </div>-->
                    <!--                                                    &lt;!&ndash;end::Navigation&ndash;&gt;-->
                    <!--                                                </b-dropdown>-->
                    <!--                                            </div>-->

                    <!--                                        </td>-->
                  </tr>
                  </tbody>
                </table>
                <v-dialog v-model="uploadDialog" max-width="600">
                  <v-card>
                    <v-card-title>
                      Change Image
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="4" v-if="user">
                          <div class="symbol-label">
                            <img v-if="user.photo" :src="user.image_path.thumb" class=""
                                 alt=""
                                 style="height: 150px;">
                            <span v-else class="symbol  symbol-light-success ">
                                                    <span class="symbol-label font-size-h5 font-weight-bold p-5"
                                                    >Upload Image</span>
                                                </span>
                          </div>
                        </v-col>
                        <v-col cols="8'">
                          <v-file-input
                              :rules="rules"
                              v-model="user.photo"
                              outlined
                              dense
                              accept="image/png, image/jpeg, image/bmp"
                              prepend-icon="mdi-camera"
                              label="Profile Image"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                          <div class="float-right">
                            <v-btn
                                @click="uploadDialog = false"
                                class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                                depressed
                            >Cancel
                            </v-btn>
                            <v-btn
                                class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                                depressed
                                @click="updateStudent"
                                :loading="isBusy"
                            >Upload & Save
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>

                    </v-card-text>

                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="600">
                  <v-card>
                    <v-toolbar dark>
                      <v-card-title>
                        ID card print settings
                      </v-card-title>

                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon  @click="dialog=false">
                          <i class="fas fa-times"></i>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              v-model="new_id_card.file_name"
                              label="File name"
                              outlined
                              dense
                          ></v-text-field>
                          <span class="text-danger"
                                v-if="$v.new_id_card.file_name.$error">This information is required.</span>
                        </v-col>
                        <v-col cols="6">
                          <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  dense
                                  outlined
                                  v-model="expiryDate"
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Card expiry (Date)"
                                  class="form-control"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="expiryDate"
                                @input="menu = false; convertAdToBs()"
                                header-menu="primary"
                            ></v-date-picker>
                          </v-menu>

                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                              v-model="dob_bs"
                              label="Card expiry (BS)"
                              prepend-icon="mdi-calendar"
                              outlined
                              dense
                              readonly
                          ></v-text-field>

                        </v-col>
                        <v-col cols="12">
                          <v-select
                              v-model="new_id_card.print_id"
                              :items="printers"
                              item-value="id"
                              item-text="title"
                              label="Select printer"
                              outlined
                              dense
                              clearable
                          ></v-select>
                          <span class="text-danger" v-if="$v.new_id_card.print_id.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12" class="text-right">

                          <v-btn
                              class="cancel-btn"
                              depressed
                              @click="dialog=false"
                          >Cancel
                          </v-btn>

                          <v-btn :loading="loading" depressed outlined @click="saveIds()"
                                 class="pull-right btn btn-primary text-white">
                            Save setting & send to prepare PDF
                          </v-btn>
                        </v-col>
                      </v-row>


                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import IdCardService from "@/core/services/IdCard/IdCardService";
import PrinterService from "@/core/services/printer/PrinterService";
import {required} from "vuelidate/lib/validators";
import {API_URL} from "@/core/config";

const idCardService = new IdCardService();
const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()

const printerService = new PrinterService();
export default {
  name: "users",
  components: {},
  validations: {
    new_id_card: {
      print_id: {required},
      file_name: {required}
    }
  },
  data() {
    return {
      import_excel: false,
      dob_bs: null,
      checkAll: false,
      isLoading: false,
      attrs: false,
      loading: false,
      dialog: false,
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      excel_file: null,
      isBusy: false,
      uploadDialog: false,
      new_id_card: {
        printer_id: null,
        file_name: null,
      },
      studentCards: [],
      printOption: [
        {
          name: "New",
          value: 'new'
        },
        {
          name: 'Printed',
          value: 'printed'
        },
        {
          name: 'Send to print',
          value: 'ready',
        }
      ],
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      expiryDate: new Date().toISOString().substr(0, 10),
      user: {
        photo: null,
      },
      blob: null,
      idCard: {
        expiryDate: null,
        studentIds: [],

      },
      user_type: [
        {name: 'Teacher', value: 'teacher'},
        {name: 'Student', value: 'student'},
        {name: 'Staff', value: 'staff'},
      ],
      search: {
        type: 'student',
        id_type: 'new',
        limit: 80,

      },
      users: [],
      selected: [],
      studentList: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      printers: [],
      levels: [],
      levelId: null,
      page: null,
      total: null,
      perPage: null
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    if (this.$route.query.first_name) {
      this.search = this.$route.query;
    }

    this.getLevels();
    // this.getPreview();
    this.getAcademicYears();
    this.getPrinters();
    this.convertAdToBs();
  },
  methods: {

    getPrinters() {
      printerService.paginate().then(response => {
        this.printers = response.data.data;
      });
    },
    getPreview() {
      idCardService.printPreview().then(response => {
        this.blob = 'data:image/png;base64,' + response.data;
        // console.log(response.data)
        // console.log(this.blob)

      });
    },
    resetFilter() {
      this.search = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: '',
        type: 'student',
        limit: 80
      }
      this.users = [];
      this.loading = false
      // this.getUsers();


    },
    changeImage(user) {
      this.user = user;
      this.uploadDialog = true;
    },
    addStudents() {
      this.selected.forEach((student) => {
        if (this.studentList.indexOf(student) === -1) {
          this.studentList.push(student);
        }
      })

    },
    removeStudentFromList(index) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.studentList.splice(index, 1);
          }
        }
      });

    },
    saveIds() {
      this.$v.new_id_card.$touch();
      if (this.$v.new_id_card.$error) {
        setTimeout(() => {
          this.$v.new_id_card.$reset();
        }, 3000);
      } else {
       this.loading = true
        if (this.studentList.length > 0) {
          this.studentList.forEach((student) => {
            this.studentCards.push({
              user_id: student.id,
              expiry_date: this.expiryDate,
              issue_date: this.date,
              issued_by: this.currentUser.id,
              printer_id: this.new_id_card.print_id,
              file_name: this.new_id_card.file_name,
            });
          })
        } else {
          this.selected.forEach((student) => {
            this.studentCards.push({
              user_id: student.id,
              expiry_date: this.expiryDate,
              issue_date: this.date,
              issued_by: this.currentUser.id,
              printer_id: this.new_id_card.print_id,
              file_name: this.new_id_card.file_name,
            });
          })
        }
        idCardService.store(this.studentCards).then((res) => {
          this.studentList = [];
          this.studentCards = [];
          this.selected = [];
          this.isBusy = false;
          this.$bus.emit('checkNotification',);
          this.displayMessage("success")
          this.new_id_card.print_id = '';
          this.new_id_card.file_name = '';
          this.expiryDate = '';
          this.dialog = false;
          this.loading = false
          this.getUsers();
        }).catch(error => {
          this.displayMessage("error")
        })
      }


    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.users.forEach(user => {
          this.selected.push(user);
        })

      }
    },
    updateStudent() {
      delete this.user.setting
      let fd = this.generateFd();
      this.isBusy = true;
      // delete this.user.email;
      // delete this.user.phone;
      userService
          .update(this.user.id, fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.uploadDialog = false;
            this.getUsers();
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },
    convertAdToBs() {
      let data = {
        dob: this.expiryDate,
      };
      idCardService
          .convertAdToBs(data)
          .then(response => {
            this.dob_bs = response.data.dob;
          })
          .catch(error => {
            //console.log(error);
          });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.user) {
        if (
            key == "photo" &&
            this.user["photo"] &&
            this.user["photo"] != null
        ) {
          if (typeof this.user["photo"] == "object") {
            fd.append("photo", this.user[key]);
          }
        } else if (key == "notification_email" && this.user["notification_email"]) {
          fd.append("notification_email", this.user.notification_email ? 1 : 0);
        } else if (key == "notification_sms") {
          fd.append("notification_sms", this.user.notification_sms ? 1 : 0);
        } else if (key == "notify_parent") {
          fd.append("notify_parent", this.user.notify_parent ? 1 : 0);
        } else if (key == "com_pref_postal") {
          fd.append("com_pref_postal", this.user.com_pref_postal ? 1 : 0);
        } else if (key == "notify_other") {
          fd.append("notify_other", this.user.notify_other ? 1 : 0);
        } else {
          if (key != "id" && this.user[key]) {
            fd.append(key, this.user[key]);
          }
        }
      }
      return fd;
    },
    getUsers() {
      this.loading = true
      idCardService.paginate(this.search, this.page).then(response => {
        // this.$bus.emit('toggleLoader');
        this.selected = [];
        this.checkAll = false
        this.users = response.data.data;
        this.loading = false
      }).catch(error=>{
        this.loading = false
      });

    },
    editUser(id) {
      return this.$router.push({name: "students-update", params: {id: id}});
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;

      });
    },
    getAcademicClasses() {
      academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information deleted");
                  this.getSlider();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    manageUserRole(id) {
      this.$router.push({name: "user-role", params: {id, id}});
    },
    importStudent() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      userService.importUser(fd).then(res => {
        this.isLoading = false
        this.excel_file = null;
        this.import_excel = false;
        this.$snotify.success("Information imported")
        this.getUsers();
      })
    }, previewIdCard() {
      let ids = [];
      if(this.studentList && this.studentList.length>0){
        this.studentList.forEach(ele => {
          ids.push(ele.id)
        })
      }
      if (ids.length==0 && this.selected.length > 0) {
        this.selected.forEach(ele => {
          ids.push(ele.id)
        })
      }
      if(ids && ids.length>0){
        let url = ''
        // if (this.search.type != 'student')
        //      url = API_URL + "id-card-teacher"
        //   else
        url = API_URL + "user/preview/id-card"

        if (ids.length > 0) {
          url = url + "?ids=" + ids
          window.open(url)
        }
        return;
      }
    }
  }
};
</script>

<style scoped>
.form-control {
  border: none !important;
}
</style>
<style>

.id-card__checkbox {
  padding-top: 0px !important;
}

.id-card__checkbox .v-messages {
  display: none;
}

.id-card__checkbox .v-input__slot {
  margin-bottom: 0px;
  background-color: transparent !important;
}
</style>
